<template>
  <div v-if="settings.basic">
    <!-- // TODO: Use common block ??? -->
    <MultiSelect ref="input" :class="{ 'is-invalid': !isValid }" v-model="state" v-bind="$attrs" v-on="listeners" :disabled="$attrs.disabled || $attrs.readonly" :options="parsed_options" :placeholder="$t(placeholder || 'Select option' + ($attrs.multiple ? 's' : ''))" :label="settings.label" :track-by="settings.trackBy" :showLabels="$attrs.showLabels || false" @keydown.tab.prevent="onTab($event)" @input="onSelect($event)" @change="onSelect($event)">
      <template v-if="$attrs.multiple && settings.select_all" slot="beforeList" slot-scope="{ values, search, isOpen }">
        <div class="multiselect__option multiselect__option--group text-center" @click="toggleSelectAll">
          <CIcon :name="all_selected ? 'cil-x' : 'cil-check-alt'" class="ml-3 mr-2" />
          {{ $t(all_selected ? 'Deselect all' : 'Select all') }}
        </div>
      </template>
      <template slot="selection" slot-scope="{ values, search, isOpen }">
        <span class="multiselect__single" v-if="values.length > 3 && !isOpen">{{ values.length }} {{ $t('options selected') }}</span>
      </template>
    </MultiSelect>
  </div>
  <div v-else ref="container" role="group" class="form-group form-row">
    <label v-if="label" :for="name" class="col-form-label" :class="settings.classes.label">{{ $t(label) + ($attrs.multiple ? ` (${(state || []).length})` : '') }}</label>
    <div :class="settings.classes.input">
      <!-- // TODO: Use common block ??? -->
      <MultiSelect ref="input" :class="{ 'is-invalid': !isValid }" v-model="state" v-bind="$attrs" v-on="listeners" :disabled="$attrs.disabled || $attrs.readonly" :options="parsed_options" :placeholder="$t(placeholder || 'Select option' + ($attrs.multiple ? 's' : ''))" :label="settings.label" :track-by="settings.trackBy" :showLabels="$attrs.showLabels || false" @keydown.tab.prevent="onTab($event)" @input="onSelect($event)" @change="onSelect($event)">
        <template v-if="$attrs.multiple && settings.select_all" slot="beforeList" slot-scope="{ values, search, isOpen }">
          <div class="multiselect__option multiselect__option--group text-center" @click="toggleSelectAll">
            <CIcon :name="all_selected ? 'cil-x' : 'cil-check-alt'" class="ml-3 mr-2" />
            {{ $t(all_selected ? 'Deselect all' : 'Select all') }}
          </div>
        </template>
        <template slot="selection" slot-scope="{ values, search, isOpen }">
          <span class="multiselect__single" v-if="values.length > 3 && !isOpen">{{ values.length }} {{ $t('options selected') }}</span>
        </template>
      </MultiSelect>
      <template v-if="option_helper">
        <div class="valid-feedback" style="display: block; color: inherit">{{ $t(option_helper) }}</div>
      </template>
      <template v-if="helper">
        <div class="valid-feedback" style="display: block; color: inherit">{{ $t(helper) }}</div>
      </template>
      <template v-if="!isValid">
        <div v-for="(error, index) in errors" :key="index" class="invalid-feedback">{{ error.message }}</div>
      </template>
    </div>
    <div v-if="settings.refresh && state" class="ml-1 float-right">
      <CButton color="secondary" @click="onRefresh">
        <CIcon name="cil-reload" />
      </CButton>
    </div>
  </div>
</template>

<script>
import MultiSelect from 'vue-multiselect'

const AMSCONFIG = { trackBy: 'id', label: 'label', response: 'id', classes: { input: 'col-sm-9', label: 'col-sm-3' } }

// TODO: FIX TAB

export default {
  name: 'AMultiSelect',
  inheritAttrs: false,
  components: {
    MultiSelect
  },
  watch: {
    value(value) {
      //this.state = value
      //this.state = this.options.find(option => '' + option[this.settings.response] == '' + value)
      this._setSelected(value)
    },
    options(options) {
      //this.parseSelected(this.value)
      this.parsed_options = options
      this.parseOptions()
    }
    /*async value(val) {
      this.parseSelected(val)
      await this.$nextTick()
        this.$forceUpdate()
    },
    async prependId() {
      this.parseOptions()
    },
    async options() {
      this.parseSelected(this.value)
      await this.$nextTick()
        this.$forceUpdate()
    },
    async config() {
      this.parseSelected(this.value)
      await this.$nextTick()
        this.$forceUpdate()
    }*/
  },
  props: {
    value: {
      type: [String, Number, Object, Array]
    },
    options: {
      type: Array, // TODO: Objects ?
      default: () => []
    },
    prependId: {
      type: Boolean
    },
    prepend: {
      type: String
    },
    config: {
      type: Object,
      default: () => {}
    },
    label: {
      type: String
    },
    horizontal: {
      type: [Boolean, Object],
      default: false
    },
    placeholder: {
      type: String
    },
    activeOnly: {
      type: [String, Boolean],
      default: false
    },
    isValid: {
      type: Boolean,
      default: true
    },
    errors: {
      type: Array
    },
    clearErrors: {
      type: Function
    },
    delay: Boolean,
    helper: String
  },
  computed: {
    name() {
      return this.$attrs.name || this.$attrs.id || this.$vnode.data.model?.expression.split('.').pop()
    },
    listeners() {
      return {
        ...this.$listeners,
        input: event => this.emit('input', event),
        change: event => this.emit('change', event)
        //select: event => this.emit('select', event)
      }
    },
    all_selected() {
      return this.$attrs.multiple && (this.state || []).length && (this.state || []).length === (this.options || []).length
    }
  },
  data() {
    return {
      settings: { ...AMSCONFIG, ...this.config },
      //selected: this.parseSelected(this.value)
      state: '', // + this.value // || undefined
      parsed_options: this.options,
      option_helper: ''
    }
  },
  mounted() {
    if (typeof this.horizontal === 'object') this.settings.classes = { ...this.settings.classes, ...this.horizontal }
    this.parseOptions()
    this.$nextTick(() => {
      this._setSelected(this.value)
    })
  },
  methods: {
    emit(emit, event) {
      if (this.clearErrors) this.clearErrors(this.name)
      if (this.$attrs.multiple) {
        const selected = []
        for (const item of event) {
          if (item && this.settings && this.settings.response) item.value = item[this.settings.response]
          //if (item.value) selected.push(item.value)
          selected.push(item.value)
        }
        this.$emit(emit, event ? selected : null)
      } else {
        if (event && this.settings && this.settings.response) event.value = event[this.settings.response]
        this.$emit(emit, event ? event.value : null)
      }
    },
    parseOptions() {
      const self = this
      //self.parsed_options = self.parsed_options.filter(item => item !== undefined)
      self.parsed_options.forEach(option => {
        if (this.$attrs['group-select'] && this.$attrs['group-values']) {
          option[this.$attrs['group-values']].forEach(item => {
            item.label = (self.prepend ? `${self.prepend} ` : '') + (self.prependId ? `(${(item.id < 10 ? '0' : '') + item.id}) ` : '') + self.$t(item.name ? item.name : ' - No name - ')
          })
        } else {
          option.label = (self.prepend ? `${self.prepend} ` : '') + (self.prependId ? `(${(option.id < 10 ? '0' : '') + option.id}) ` : '') + self.$t(option.name ? option.name : ' - No name - ')
        }
      })
      if ((self.prepend || self.prependId) && self.settings) self.settings.label = 'label'
      if (this.activeOnly) self.parsed_options = self.parsed_options.filter(item => !!item[this.activeOnly !== true ? this.activeOnly : '_active'])
    },
    /*parseSelected(value) {
      const self = this
      self.parseOptions()
      if (value && self.settings && self.settings.response && typeof value !== 'object') {
        value = self.options.find(option => option[self.settings.response] == value)
      }
      self.selected = value
      return value

      /*
      Different elements need different event types:

      element	event type	property
      <input[type="checkbox"]>	change	checked
      <input[type="radio"]>	change	checked
      <input>	input	value
      <textarea>	input	value
      <option>
      */
    //},

    // TODO: test it
    onSelect(e) {
      e = e || {}
      this.option_helper = e.helper || ''
      // TODO: When disabled reset select
      if (e.disabled) {
        e.value = ''
        this.state = ''
      }
      if (this.$attrs.multiple !== undefined) {
        this.$emit('update:value', e.value, e)
        return
      }
      // TODO: validate disabled
      if (e && e.target && e.target.selectedOptions) {
        const optionIndex = e.target.selectedOptions[0].dataset.key
        const option = this.options[optionIndex]
        const value = option.value !== undefined ? option.value : option
        this.state = value // TODO: validate disabled here !!!
        this.$emit('update:value', value, e)
      }
    },
    onTab(e) {
      console.log('TABBB', e)
    },

    onRefresh() {
      document.location.reload() // TODO: Improve this
    },

    toggleSelectAll(e) {
      let values = []
      if (!this.all_selected) {
        this.$set(this, 'state', [])
        for (const item of this.options) {
          this.state.push(item)
          if (item && this.settings && this.settings.response) values = [...values, ...[item[this.settings.response]]]
        }
      } else {
        this.$set(this, 'state', [])
        // TODO: check this
        //this.$refs.container.$el.scrollIntoView()
        //this.$nextTick(() => this.$refs.container.showCurrent())
        //window.scrollTo(0, 0)
      }
      //this.all_selected = !this.all_selected
      //this.$emit('update:value', this.state, e)
      this.$emit('input', values)
    },

    _setSelected(value) {
      if (typeof value === 'string' && (value[0] === '[' || value[0] === '{')) value = JSON.parse(value)
      if (typeof value === 'string' || typeof value === 'number') {
        value = this.options.find(option => option[this.settings.response] == value)
      } else if (Array.isArray(value)) {
        let selected = []
        for (const item of value) {
          let options = []
          if (this.$attrs['group-select']) {
            this.options.forEach(item => {
              options = options.concat(item[this.$attrs['group-values']])
            })
          } else options = this.options
          //selected.push(options.find(option => option[this.settings.response] == item))
          selected = [...selected, ...options.filter(option => option[this.settings.response] == item)]
        }
        value = selected
        //if (this.all_selected && value.length !== this.options.length) this.all_selected = false
      }
      this.$set(this, 'state', value)
    }
  }
}
</script>

<style lang="scss">
.multiselect__select {
  height: 34px;
}

.multiselect__tags {
  max-height: 120px;
  overflow: auto;
}

//.multiselect:read-only .multiselect__tags {
.multiselect--disabled {
  //color: #5c6873 !important;
  color: black !important;

  .multiselect__tags {
    //background-color: #e3e7ea !important; // TODO: use variable ?
    background-color: #dcdcdc !important; // TODO: use variable ?
  }
  .multiselect__single {
    background-color: transparent;
  }
}

.c-dark-theme {
  .multiselect--active {
    border-color: rgba(255, 255, 255, 0.03) !important;
  }

  .multiselect__tags {
    border-color: rgba(255, 255, 255, 0.03) !important;
    background-color: rgba(255, 255, 255, 0.05);
  }

  .multiselect__input,
  .multiselect__content-wrapper {
    color: rgba(255, 255, 255, 0.87);
    border-color: #373841; //rgba(255, 255, 255, 0.03) !important;
    background-color: #2f3039; // rgba(255, 255, 255);
  }

  .multiselect__single {
    color: white;
    background-color: #2f3039;
  }

  .multiselect__placeholder {
    color: #afafaf55 !important;
  }

  .multiselect--disabled {
    .multiselect__tags {
      background-color: rgba(255, 255, 255, 0.3) !important; // TODO: use variable ?
    }

    .multiselect__single {
      background-color: transparent;
    }
  }
}
</style>
