var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.settings.basic)?_c('div',[_c('MultiSelect',_vm._g(_vm._b({ref:"input",class:{ 'is-invalid': !_vm.isValid },attrs:{"disabled":_vm.$attrs.disabled || _vm.$attrs.readonly,"options":_vm.parsed_options,"placeholder":_vm.$t(_vm.placeholder || 'Select option' + (_vm.$attrs.multiple ? 's' : '')),"label":_vm.settings.label,"track-by":_vm.settings.trackBy,"showLabels":_vm.$attrs.showLabels || false},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }$event.preventDefault();return _vm.onTab($event)},"input":function($event){return _vm.onSelect($event)},"change":function($event){return _vm.onSelect($event)}},scopedSlots:_vm._u([{key:"beforeList",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return (_vm.$attrs.multiple && _vm.settings.select_all)?[_c('div',{staticClass:"multiselect__option multiselect__option--group text-center",on:{"click":_vm.toggleSelectAll}},[_c('CIcon',{staticClass:"ml-3 mr-2",attrs:{"name":_vm.all_selected ? 'cil-x' : 'cil-check-alt'}}),_vm._v(" "+_vm._s(_vm.$t(_vm.all_selected ? 'Deselect all' : 'Select all'))+" ")],1)]:undefined}},{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(values.length > 3 && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(values.length)+" "+_vm._s(_vm.$t('options selected')))]):_vm._e()]}}],null,true),model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},'MultiSelect',_vm.$attrs,false),_vm.listeners))],1):_c('div',{ref:"container",staticClass:"form-group form-row",attrs:{"role":"group"}},[(_vm.label)?_c('label',{staticClass:"col-form-label",class:_vm.settings.classes.label,attrs:{"for":_vm.name}},[_vm._v(_vm._s(_vm.$t(_vm.label) + (_vm.$attrs.multiple ? (" (" + ((_vm.state || []).length) + ")") : '')))]):_vm._e(),_c('div',{class:_vm.settings.classes.input},[_c('MultiSelect',_vm._g(_vm._b({ref:"input",class:{ 'is-invalid': !_vm.isValid },attrs:{"disabled":_vm.$attrs.disabled || _vm.$attrs.readonly,"options":_vm.parsed_options,"placeholder":_vm.$t(_vm.placeholder || 'Select option' + (_vm.$attrs.multiple ? 's' : '')),"label":_vm.settings.label,"track-by":_vm.settings.trackBy,"showLabels":_vm.$attrs.showLabels || false},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }$event.preventDefault();return _vm.onTab($event)},"input":function($event){return _vm.onSelect($event)},"change":function($event){return _vm.onSelect($event)}},scopedSlots:_vm._u([{key:"beforeList",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return (_vm.$attrs.multiple && _vm.settings.select_all)?[_c('div',{staticClass:"multiselect__option multiselect__option--group text-center",on:{"click":_vm.toggleSelectAll}},[_c('CIcon',{staticClass:"ml-3 mr-2",attrs:{"name":_vm.all_selected ? 'cil-x' : 'cil-check-alt'}}),_vm._v(" "+_vm._s(_vm.$t(_vm.all_selected ? 'Deselect all' : 'Select all'))+" ")],1)]:undefined}},{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(values.length > 3 && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(values.length)+" "+_vm._s(_vm.$t('options selected')))]):_vm._e()]}}],null,true),model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},'MultiSelect',_vm.$attrs,false),_vm.listeners)),(_vm.option_helper)?[_c('div',{staticClass:"valid-feedback",staticStyle:{"display":"block","color":"inherit"}},[_vm._v(_vm._s(_vm.$t(_vm.option_helper)))])]:_vm._e(),(_vm.helper)?[_c('div',{staticClass:"valid-feedback",staticStyle:{"display":"block","color":"inherit"}},[_vm._v(_vm._s(_vm.$t(_vm.helper)))])]:_vm._e(),(!_vm.isValid)?_vm._l((_vm.errors),function(error,index){return _c('div',{key:index,staticClass:"invalid-feedback"},[_vm._v(_vm._s(error.message))])}):_vm._e()],2),(_vm.settings.refresh && _vm.state)?_c('div',{staticClass:"ml-1 float-right"},[_c('CButton',{attrs:{"color":"secondary"},on:{"click":_vm.onRefresh}},[_c('CIcon',{attrs:{"name":"cil-reload"}})],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }